import { router } from "utils/APIUrls"
import axiosConnectorInstance from "./AxiosConnector"

export const getUser = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_user,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Manage Category
export const addCategory = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.add_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editCategory = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getCategories = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_category,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}
export const getCategoriesById = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_categoryById}?catId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const deleteCategory = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.delete_category}?catId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Manage Block User
export const blockUser = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.block_user}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const unblockUser = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.unblock_user}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Update Gst Status
export const approveGstStatus = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.approve_gst_status}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const disapproveGstStatus = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.disapprove_gst_status}?userId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

// get order details

export const getRetailOrderDetails = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_retail_order_details
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getBulkOrderDetails = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      router?.get_bulk_order_details
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editImgOrder = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_img_order,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editItemData = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_item_data,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getItemsDetails = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_items_details}?orderId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Order Status
export const acceptOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.accept_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const rejectOrder = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.reject_order}?orderId=${requestPayload.orderId}&note=${requestPayload.note}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const processingOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.processing_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const readyToShipOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.ready_to_ship_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const shippingOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.shipping_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const deliveredOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.delivered_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const readyToPickupOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.ready_to_pickup_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const pickupDoneOrder = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.pickup_done_order}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getOrderType = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_order_status}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Materials

export const getMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(router?.get_materials)

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const addMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.add_material,
      requestPayload
    )
    // console.log("Add material response", response)
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_material,
      requestPayload
    )
    // console.log("Add material response", response)
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const sortMaterials = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.sort_material,
      requestPayload
    )
    // console.log("Add material response", response)
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const deleteMaterial = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.delete_materials}?materialId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

// Store Notifcation
export const storeNotification = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.store_notification}?orderId=${id}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const orderStatusNotification = async (id, type) => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.order_status_notification}?orderId=${id}&statusType=${type}`
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

//Query

export const getRetailQueryDetails = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(router?.get_retail_query)

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getBulkQueryDetails = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.get(router?.get_bulk_query)

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const getQueryDetails = async id => {
  try {
    const response = await axiosConnectorInstance.get(
      `${router?.get_query_detail}?queryId=${id}`
    )
    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editQueryReply = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_query,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

export const editQueryItem = async requestPayload => {
  try {
    const response = await axiosConnectorInstance.post(
      router?.edit_queryItem,
      requestPayload
    )

    // console.log("Delete event response", response);
    return response.data
  } catch (error) {
    console.log("errorerrorerror", error)
    return error.response.data
  }
}

