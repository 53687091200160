import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span className="d-flex align-items-center">
      <input
        className="form-control"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search ${count} records...`}
        style={{ marginLeft: "10px", width: "300px" }}
      />
    </span>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  customPageSize,
  tableClass,
  theadClass,
  paginationDiv,
  pagination,
}) => {
  const savedPageIndex = parseInt(localStorage.getItem("pageIndex") || "0", 10)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: savedPageIndex,
        pageSize: customPageSize || 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state

  // Save the pageIndex to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("pageIndex", pageIndex)
  }, [pageIndex])

  return (
    <Fragment>
      <Row className="mb-3">
        <Col md={6}>
          {isGlobalFilter ? (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          ) : null}
        </Col>
        <Col md={6} className="text-right">
          {isAddOptions && (
            <Button color="primary">
              <Link to="/add-new" className="text-white">
                Add New
              </Link>
            </Button>
          )}
        </Col>
      </Row>
      <Table
        {...getTableProps()}
        className={`table ${tableClass} table-bordered`}
      >
        <thead className={theadClass}>
          {headerGroups.map((headerGroup, ind) => (
            <tr key={ind} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  key={`${ind}-${column.id}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td key={cell.column.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {/* <Row className={paginationDiv}>
        <Col className={pagination}>
          <div className="pagination-container">
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="pagination-btn"
            >
              {"<<"}
            </Button>{" "}
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="pagination-btn"
            >
              {"<"}
            </Button>{" "}
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="pagination-btn"
            >
              {">"}
            </Button>{" "}
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="pagination-btn"
            >
              {">>"}
            </Button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: "50px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row> */}

      <Row className={paginationDiv}>
        <Col className={pagination}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              gap: "10px",
              backgroundColor: "#f0f4ff",
              borderRadius: "8px",
            }}
          >
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{
                backgroundColor: "#007bff",
                border: "none",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: canPreviousPage ? "pointer" : "not-allowed",
              }}
            >
              {"<<"}
            </Button>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{
                backgroundColor: "#007bff",
                border: "none",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: canPreviousPage ? "pointer" : "not-allowed",
              }}
            >
              {"<"}
            </Button>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "#565b61",
              }}
            >
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{
                backgroundColor: "#007bff",
                border: "none",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: canNextPage ? "pointer" : "not-allowed",
              }}
            >
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{
                backgroundColor: "#007bff",
                border: "none",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: canNextPage ? "pointer" : "not-allowed",
              }}
            >
              {">>"}
            </Button>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                min="1"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{
                  width: "40px",

                  marginLeft: "10px",
                  padding: "3px",
                  border: "1px solid #007bff",
                  borderRadius: "4px",
                }}
              />
            </span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              style={{
                marginLeft: "10px",
                padding: "3px",
                border: "1px solid #007bff",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isGlobalFilter: PropTypes.bool,
  isAddOptions: PropTypes.bool,
  customPageSize: PropTypes.number,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  paginationDiv: PropTypes.string,
  pagination: PropTypes.string,
}

export default TableContainer

 /* 
 
 5k angel
 1k dubet
 3k chirag
 1k expert 
 
 */