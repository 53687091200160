import React, { useCallback, useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Modal, Table, Button } from "react-bootstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  FaTrash,
  FaPencilAlt,
  FaFilePdf,
  FaEye,
  FaUpload,
} from "react-icons/fa"

import Swal from "sweetalert2"
import { toast, ToastContainer } from "react-toastify"
import {
  addMaterials,
  deleteMaterial,
  editCategory,
  editMaterials,
  getMaterials,
  sortMaterials,
} from "services/ApiService"
import "./Material.css"
import { materialDoc_url } from "utils/APIUrls"
import Loader from "components/Common/Loader"
import { debounce } from "lodash"
// import { cl } from "@fullcalendar/core/internal-common"

const Material = () => {
  const [materials, setMaterials] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showUpdateModal, setUpdateShowModal] = useState(false)
  const [newMaterial, setNewMaterial] = useState("")
  const [newDensity, setNewDensity] = useState("")
  const [error, setError] = useState("")
  const [density, setDensity] = useState("")
  const [searchQuery, setSearchQuery] = useState("") // State for search query
  const [isLoading, setIsLoading] = useState(true)
  const [updatedMaterial, setUpdatedMaterial] = useState({
    id: "",
    material: "",
    density: "",
  })

  const fetchMaterial = () => {
    getMaterials().then(res => {
      setMaterials(res.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchMaterial()
  }, [])

  const handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(materials)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setMaterials(items)

    const updatedMaterialsList = items.map((material, index) => ({
      id: material.id,
      material: material.material,
      sortKey: index,
    }))

    sortMaterials(updatedMaterialsList)
      .then(() => fetchMaterial())
      .catch(console.error)
  }

  const handleDelete = materialId => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMaterial(materialId)
          .then(res => {
            fetchMaterial()
            toast.success(res.msg)
          })
          .catch(() =>
            Swal.fire("Error!", "Failed to delete material.", "error")
          )
      }
    })
  }

  const updateMaterials = (e, id) => {
    const file = e.target.files[0]
    // console.log("idd of materials....",id)
    // Create FormData to send file
    const formData = new FormData()
    formData.append("materialDoc", file)
    formData.append("matId", id)

    editMaterials(formData).then(res => {
      fetchMaterial()
    })
    // console.log("PDF submit", e.target.files[0])
  }

  const handleAddMaterial = () => {
    if (!newMaterial || !newDensity) {
      setError("Both fields are required!")
      return // Prevent form submission if fields are empty
    }

    // Clear error if fields are valid
    setError("")

    addMaterials({
      material: newMaterial,
      density: newDensity,
    })
      .then(res => {
        toast.success(res.msg)
        fetchMaterial()
        setNewMaterial("")
        setNewDensity("")
        setShowModal(false)
      })
      .catch(console.error)
  }

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  const densitUpdate = value => {
    console.log("Data Of Density Change from new function::- ", value)

    editMaterials(value)
      .then(res => {
        toast.success("Density updated successfully!!")
      })
      .catch(err => {
        console.log("err in Material Update", err)
      })
  }

  var debouncedApi = useCallback(
    debounce(value => densitUpdate(value), 700),
    []
  )

  const handleDensityChange = (id, density) => {
    // console.log("material ID :-  ", id)
    // console.log("material Density :-  ", density)
    const reqObj = {
      matId: id,
      materialDensity: density,
    }

    setMaterials(prevData =>
      prevData.map(material =>
        material.id === id
          ? { ...material, materialDensity: density }
          : material
      )
    )

    debouncedApi(reqObj)
  }

  const filteredMaterials = materials.filter(material =>
    material.material.toLowerCase().includes(searchQuery.toLowerCase())
  )

  console.log("Filter materialss....", filteredMaterials)
  
  return (
    <div className="main-div">
      <Breadcrumbs title="Dashboard" breadcrumbItem="Materials" />

      <div className="row">
        <div className="col-sm-6 col-12 search-container">
          <input
            type="search"
            placeholder={`${materials.length} records...`}
            value={searchQuery}
            onChange={handleSearch}
            className="search-input" // Add a CSS class for styling
          />
        </div>

        <div className="col-sm-6 col-12 d-flex flex-row-reverse  ">
          <div className="">
            <button
              className="badge bg-primary d-inline-block font-size-13 text-center border-0"
              style={{
                padding: "12px 19px",
                minWidth: "100px",
                borderRadius: "17px",
                marginRight: "20px",
              }}
              onClick={() => setShowModal(true)}
            >
              + Add Materials
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : filteredMaterials.length > 0 ? (
        <div className="table-div">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="materials">
              {provided => (
                <Table
                  striped
                  bordered
                  hover
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                > 
                  <thead>
                    <tr>
                      <th className="font-size-13">No.</th>
                      <th className="font-size-13">Material Name</th>
                      <th className="font-size-13">Material Density</th>
                      <th className="font-size-13">Description</th>
                      <th className="font-size-13">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font-size-13">
                    {filteredMaterials.map(
                      (
                        { id, material, materialDoc, materialDensity },
                        index
                      ) => (
                        <Draggable key={id} draggableId={id} index={index}>
                          {provided => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td>{index + 1}</td>
                              <td>{material}</td>
                              <td>
                                <input
                                  type="text"
                                  id="density"
                                  onChange={e =>
                                    handleDensityChange(id, e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Enter density"
                                  style={{
                                    borderRadius: "10px",
                                    padding: "8px 12px",
                                  }}
                                  value={materialDensity}
                                />
                              </td>
                              {materialDoc ? (
                                <td>
                                  <button
                                    className="btn btn-outline-primary view-doc-btn"
                                    onClick={() =>
                                      window.open(
                                        `${materialDoc_url}${materialDoc}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <FaEye className="me-1" /> View Doc
                                  </button>
                                </td>
                              ) : (
                                // <td>
                                //   <input
                                //     type="file"
                                //     // onChange={(e) => console.log("PDF submit",e.target.files[0] )}
                                //     onChange={e => updateMaterials(e, id)}
                                //   />
                                // </td>

                                <td>
                                  <label
                                    htmlFor={`file-upload-${id}`}
                                    className="upload-btn"
                                  >
                                    <FaUpload className="me-1" /> Upload
                                    Document
                                    <input
                                      id={`file-upload-${id}`}
                                      type="file"
                                      className="d-none" // Hide default file input
                                      onChange={e => updateMaterials(e, id)}
                                    />
                                  </label>
                                </td>
                              )}
                              <td>
                                <button
                                  className="btn p-0 d-inline-block font-size-20 text-center border-0 delete-button"
                                  onClick={() => handleDelete(id)}
                                  style={{ borderRadius: "5px" }}
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="text-center mt-4">
          <h5>No Data Found</h5>
        </div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="text-danger mb-3">{error}</div>}
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter material"
            value={newMaterial}
            required
            onChange={e => setNewMaterial(e.target.value)}
          />

          <input
            type="text"
            className="form-control"
            placeholder="Enter density"
            value={newDensity}
            required
            onChange={e => setNewDensity(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false)
              setNewMaterial("")
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddMaterial}>
            Add Material
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Material"
            value={newMaterial}
            onChange={e => setNewMaterial(e.target.value)}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Density"
            value={newMaterial}
            onChange={e => setNewMaterial(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateShowModal(false)
              setNewMaterial("")
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddMaterial}>
            Add Material
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  )
}

export default Material
