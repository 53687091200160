import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
import { SocketProvider, useSocket } from "./utils/socket"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"



const getLayout = layoutType => {
  let Layout = VerticalLayout
  return Layout
}

const App = () => {
  const socket = useSocket()
  // const [socket, setSocket] = useState(null)

  // useEffect(() => {
  //   const token = localStorage.getItem("authorizedToken")
  //   // Establish the socket connection
  //   const socketInstance = createSocketConnection(token)
  //   // Save the socket instance in the state (if needed later)
  //   setSocket(socketInstance)

  //   // Cleanup on unmount (close the socket connection)
  //   return () => {
  //     if (socketInstance) socketInstance.disconnect()
  //   }
  // }, [])

  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  return (
    <React.Fragment>
      <SocketProvider>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      </SocketProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
