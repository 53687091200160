// import { io } from "socket.io-client"
// import { socket_url } from "./APIUrls"

// // URL of your backend
// const SOCKET_URL = socket_url // Make sure to update with your backend URL if different

// // Function to create the socket connection
// const createSocketConnection = token => {
//   // Connect to the server with the token passed in the query
//   const socket = io(SOCKET_URL, {
//     query: { token }, // Send token as a query parameter
//     transports: ["websocket"], // Specify WebSocket as the transport method
//     reconnectionAttempts: 5, // Try to reconnect 5 times
//     reconnectionDelay: 2000, // Delay between reconnection attempts (2 seconds)
//   })

//   // Listen for successful connection
//   socket.on("connect", () => {
//     console.log("Connected to Socket.IO server with ID:", socket.id)
//   })

// //   // Handle connection error
// //   socket.on("connect_error", err => {
// //     console.log("Socket connection error:", err.message)
// //   })

//   // Listen for incoming messages (example for receiving messages)
//   socket.on("receive-message", data => {
//     console.log("Message received:", data)
//   })

//   // Handle disconnection
//   socket.on("disconnect", () => {
//     console.log("Disconnected from Socket.IO server")
//   })

//   return socket
// }

// export default createSocketConnection;

import React, { createContext, useContext, useEffect, useState } from "react"
import { io } from "socket.io-client" // Import io function
import { useDispatch } from "react-redux"
import { base_url, socket_url } from "./APIUrls"
import { RECIEVE_MESSAGES, SEND_MESSAGES } from "./SocketEvents"
import { set } from "lodash"

const SocketContext = createContext(null)
const token = localStorage.getItem("authorizedToken")

export const useSocket = () => {
  return useContext(SocketContext)
}

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const newSocket =
      token &&
      io(`${socket_url}?token=${token}`, {
        reconnection: true, // Enable reconnection
        reconnectionDelay: 1000,
      })
    console.log("newSocket", newSocket)

    if (newSocket) {
      newSocket.on("connect", () => {
        console.log("Socket Connected to the server", newSocket)
      })

      newSocket.on(RECIEVE_MESSAGES, data => {
        // console.log("Socket Connected to the server")
        // console.log("Socket Received RECIEVE_MESSAGES:", data)
      })

      setTimeout(() => 
        {
        newSocket.emit(
          SEND_MESSAGES,
          // console.log("send msg",JSON.stringify({
          //     message: "Test message",
          //     image: "854578454648484-img1.jpg",
          //     channelId: "6718ca405cd9acd862352131",
          //   }))
          {
            message: "Test message",
            image: "854578454648484-img1.jpg",
            channelId: "6718ca405cd9acd862352131",
          }
        )
      }, 2000)

      newSocket.on("connect_error", error => {
        console.error("Socket connection error:", error)
      })

      newSocket.on("disconnect", () => {
        console.log("Socket Disconnected from the server")
      })

      setSocket(newSocket)

      return () => {
        console.log("Closing socket connection...")
        newSocket.disconnect()
      }
    }

    return () => {
      if (newSocket) {
        newSocket.disconnect()
      }
    }
  }, [])

  // console.log("socket provider", socket);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
