import React from "react"

function Loader() {
  return (
    <div className="order-loader">
      <div className="spinner-border  h-full text-primary "></div>
    </div>
  )
}

export default Loader   
