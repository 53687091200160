import PropTypes from "prop-types"
import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/S.svg"
import { base_url, router } from "utils/APIUrls"

const Login = props => {
  const [otp, setOtp] = useState("")
  const navigate = useNavigate(); 

  var handleSubmit = e => {
    
    var emailOrPhone = localStorage.getItem("user")
    e.preventDefault()
    const reqObj = {
      email: emailOrPhone,
      otp:otp
    }
    axios
      .post(base_url + router.otp_verify, reqObj)
      .then(res => {
        localStorage.setItem("authorizedToken", res.data.token);
        toast.success(res.data.msg || "OTP Verified Successfully")
        navigate('/users')
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          err?.response?.data?.errors.forEach(error => {
            console.log(error.msg)

            toast.error(error.msg)
          })
        } else {
          toast.error(err?.response?.data?.message || "An error occured")
        }
      })
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div
        className="account-pages my-5 pt-sm-5 vh-90 d-flex align-items-center"
        style={{ minHeight: "85vh" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h4 className="text-primary">OTP Verify</h4>
                        <p>Sign in to continue to Stelo.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="70"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <Label className="form-label">OTP</Label>
                        <Input
                          name="otp"
                          className="form-control"
                          placeholder="Enter otp"
                          type="text"
                          onChange={e => setOtp(e.target.value)}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Verify
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
