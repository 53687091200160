/* TASKS */
export const GET_TASKS = "GET_TASKS"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_FAIL = "GET_TASKS_FAIL"

export const DELETE_KANBAN = "DELETE_KANBAN"
export const DELETE_KANBAN_SUCCESS = "DELETE_KANBAN_SUCCESS"
export const DELETE_KANBAN_FAIL = "DELETE_KANBAN_FAIL"

export const ADD_CARD_DATA = "ADD_CARD_DATA"
export const ADD_CARD_DATA_SUCCESS = "ADD_CARD_DATA_SUCCESS"
export const ADD_CARD_DATA_FAIL = "ADD_CARD_DATA_FAIL"

export const UPDATE_CARD_DATA = "UPDATE_CARD_DATA"
export const UPDATE_CARD_DATA_SUCCESS = "UPDATE_CARD_DATA_SUCCESS"
export const UPDATE_CARD_DATA_FAIL = "UPDATE_CARD_DATA_FAIL"
